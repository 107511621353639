import React, { Component } from "react"
import { Link } from "gatsby"
import DocumentTitle from "react-document-title"
import Layout from "../../components/layout"

export default class Work extends Component {
    render() {
        const caseItems = [
            {
                title: "Box8",
                description: "Food tech",
                link: "/work/box8",
            },
            {
                title: "Enchanting Travels",
                description: "Travel management, planning & booking",
                link: "/work/enchanting-travels",
            },
            {
                title: "WoW Club",
                description: "Women only travel experience",
                link: "/work/wow-club",
            },
            {
                title: "BiblioApp",
                description: "Discover the literary world of books & more.",
                link: "/work/biblioapp",
            },
        ]

        const workItems = [
            {
                title: "Audio Features Visualisation",
                description:
                    "Visualisation of audio features using Spotify API. Built with node + react + chartjs",
                link:
                    "https://github.com/ananyaneogi/audio-feature-visualisation",
                article: "",
                demo: "https://audio-features.herokuapp.com/",
            },
            {
                title: "Find A Cocktail",
                description:
                    "A PWA built with ReactJS. Save cocktail recipes offline.",
                link: "https://github.com/ananyaneogi/find-a-cocktail",
                article: "",
                demo: "https://find-a-cocktail.firebaseapp.com/",
            },
            {
                title: "A Vue Shop",
                description:
                    "E-commerce website built with Vue JS + Vuex + Vue Router.",
                link: "https://github.com/ananyaneogi/a-vue-shop",
                article: "",
                demo: "https://ananyaneogi.github.io/a-vue-shop/",
            },
            {
                title: "Dark theme with Ambient Light API",
                description:
                    "Enabling Dark Mode On Websites Based On Surrounding Light.",
                link: "https://github.com/ananyaneogi/dark-theme",
                article:
                    "https://dev.to/ananyaneogi/enabling-dark-mode-on-websites-based-on-surrounding-light--3jel",
                demo: "",
            },
            {
                title: "Picture-in-Picture",
                description:
                    "Add any video from your computer and play the video in Picture-in-Picture mode.",
                link: "https://github.com/ananyaneogi/picture-in-picture-demo",
                article:
                    "https://dev.to/ananyaneogi/implement-picture-in-picture-on-the-web-17g8",
                demo: "",
            },
        ]

        const caseCards = caseItems.map(work => (
            <div className="work-card" key={work.title}>
                <h3>{work.title}</h3>
                <p className="work-desc">{work.description}</p>
                <Link to={work.link}>View details</Link>
            </div>
        ))

        const workCards = workItems.map(work => (
            <div className="work-card" key={work.title}>
                <h3>{work.title}</h3>
                <p className="work-desc">{work.description}</p>
                <div className="links-wrap">
                    <a
                        href={work.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View code
                    </a>
                    {work.article ? (
                        <a
                            href={work.article}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View post
                        </a>
                    ) : (
                        ""
                    )}
                    {work.demo ? (
                        <a
                            href={work.demo}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Live demo
                        </a>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        ))
        return (
            <Layout>
                <DocumentTitle title="Ananya Neogi - Work">
                    <div className="width-container work-container">
                        {/* <p style={{marginBottom: 70}}>
            Currently working at Shopify, as a frontend developer. 
        </p>
                <hr></hr> */}

                        <h4 className="project-container-info">
                            Projects from past
                        </h4>
                        <div className="work-card-wrapper">{caseCards}</div>
                        <h4 className="project-container-info">Few more</h4>
                        <div className="work-card-wrapper">{workCards}</div>
                        <hr></hr>
                        <p>
                            Find more on{" "}
                            <a
                                href="https://github.com/ananyaneogi"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                github
                            </a>
                            .
                        </p>
                    </div>
                </DocumentTitle>
            </Layout>
        )
    }
}
